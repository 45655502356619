@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.5) inset;
  z-index: -1;
  cursor: $cursor-default;
}
