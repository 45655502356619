#root {
  --primary: #eaeaea;
  --white: #fff;
  --gray: #d1d1d1;
  --gray-dark: #a7a7a7;
  --black: #231d1d;
  --black-light: #3f3535;
}

#root[data-theme='dark'] {
  --primary: #363636;
  --white: #231d1d;
  --gray: #a7a7a7;
  --gray-dark: #d1d1d1;
  --black: #eaeaea;
  --black-light: #cccccc;
}

$primary: var(--primary);
$white: var(--white);
$gray: var(--gray);
$black: var(--black);
$gray-dark: var(--gray-dark);
$black-light: var(--black-light);
$cursor-pointer: url('/png/pointer.png'), pointer;
$cursor-default: url('/png/hand.png'), auto;
$cursor-click: url('/png/click.png'), pointer;
